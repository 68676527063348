import { template as template_fc3b16dd0b0d4d8ab06cf19b78d6676b } from "@ember/template-compiler";
const SidebarSectionMessage = template_fc3b16dd0b0d4d8ab06cf19b78d6676b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
