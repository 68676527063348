import { template as template_fc2bd2a39f174ad7b6e63a1c2504aad8 } from "@ember/template-compiler";
const FKLabel = template_fc2bd2a39f174ad7b6e63a1c2504aad8(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
