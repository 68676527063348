import { template as template_8d5e905b38f54cdb8d44648f7d48bb52 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PostMenuShareButton = template_8d5e905b38f54cdb8d44648f7d48bb52(`
  <DButton
    class="post-action-menu__share share"
    ...attributes
    @action={{@buttonActions.share}}
    @icon="d-post-share"
    @label={{if @showLabel "post.controls.share_action"}}
    @title="post.controls.share"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostMenuShareButton;
